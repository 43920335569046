<template lang="pug">
  div.row
    div.col-12
      div.form-check.form-switch
        input.form-check-input#show-del-switch(type="checkbox", v-model="show_all", @change="get")
        label.form-check-label(for="show-all-switch") 全て表示する
    div.col-12
      VueGoodTable.at2(:columns="columns", :rows="rows", :pagination-options="pagination_options", styleClass="vgt-table striped condensed", :sort-options="{ enabled:true, initialSortBy:{field:'id', type:'desc'}}", :row-style-class="rowStyleClassFn")
        template(slot="table-row", slot-scope="props")
          span(v-if="props.column.field == 'url'")
            a(:href="props.row.i.url", target="_blank", rel="noopener", :class="props.row.st=='AccessFailed'? 'text-decoration-line-through':''") {{ props.row.i.url }}
          span(v-else-if="props.column.field == 'u'")
            router-link(:to="{ path:'user', query:{ id:props.row.u } }") {{ props.row.u }}
          div(v-else-if="props.column.field == 'st'")
            select.form-select.form-select-sm(:value="props.row.st", @change="change_status(props.row.id, $event)")
              option(v-for="s in status", :key="s") {{ s }}
          span(v-else-if="props.column.field == 'sp'")
            <svg v-if="props.row.sp" data-v-080e764a="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-check-circle"><path data-v-080e764a="" d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline data-v-080e764a="" points="22 4 12 14.01 9 11.01"></polyline></svg>
          span(v-else-if="props.column.field == 'f'")
            <svg v-if="props.row.f" data-v-080e764a="" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-v-12cac6b8="" class="feather feather-check-circle"><path data-v-080e764a="" d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path><polyline data-v-080e764a="" points="22 4 12 14.01 9 11.01"></polyline></svg>
          span(v-else-if="props.column.field == 'n'")
            a.link(:class="(props.row.n.length+props.row.na.length)!==0? 'fill':''", type="button", @click="show_note(props.row.id, props.row.n, props.row.na, props.row.i)")
              <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="feather"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
          span(v-else)
            | {{ props.formattedRow[props.column.field] }}

        template(slot="table-column", slot-scope="props")
          span(v-if="props.column.field == 'u'")
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-user" data-v-080e764a=""><path d="M20 21v-2a4 4 0 0 0-4-4H8a4 4 0 0 0-4 4v2"></path><circle cx="12" cy="7" r="4"></circle></svg>
          span(v-else-if="props.column.field == 'n'")
            <svg viewBox="0 0 24 24" width="24" height="24" stroke="currentColor" stroke-width="2" fill="none" stroke-linecap="round" stroke-linejoin="round" class="feather"><path d="M21 11.5a8.38 8.38 0 0 1-.9 3.8 8.5 8.5 0 0 1-7.6 4.7 8.38 8.38 0 0 1-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 0 1-.9-3.8 8.5 8.5 0 0 1 4.7-7.6 8.38 8.38 0 0 1 3.8-.9h.5a8.48 8.48 0 0 1 8 8v.5z"></path></svg>
          span(v-else)
            | {{ props.column.label }}

    div.d-none#note-modal
      div.text-center
        h5 {{ id }}
      div.text-start.mt-3
        label.form-label Device
        input.form-control(type="text", :value="JSON.stringify(info.device)", readonly)
      div.text-start.mt-3
        label.form-label UserAgent
        textarea.form-control(:value="info['user-agent']", rows=3, readonly)
      div.text-start.mt-3
        label.form-label Note
        textarea.form-control(v-model="note", rows=3, readonly)
      div.text-start.mt-3
        label.form-label Note Admin
        textarea.form-control(v-model="note_admin", rows=4)

</template>

<script>
import 'vue-good-table/dist/vue-good-table.css'
import { VueGoodTable } from 'vue-good-table';

export default {
  name:"AttemptTargetsV2",
  components:{
    VueGoodTable
  },
  data (){
    return {
      pagination_options: {
        enabled: true,
        perPage: 100,
        perPageDropdown: [25,50,100,200]
      },
      columns: [
        {
          label: 'ID',
          field: 'id',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
        },
        {
          label: 'User',
          field: 'u',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          filterOptions: { enabled: true },
        },
        {
          label: 'Status',
          field: 'st',
          width: '150px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
          filterOptions: { 
            enabled: true, trigger: 'enter',
            filterDropdownItems: [ 
              { value: 'Attempt', text: 'Attempt' },
              { value: 'Registered', text: 'Registered' },
              { value: 'Fail', text: 'Fail' },
              { value: 'Survey', text: 'Survey' },
              { value: 'Working', text: 'Working' },
              { value: 'Complete', text: 'Complete' },
              { value: 'Notified', text: 'Notified' },
              { value: 'UserComplete', text: 'UserComplete' },
              { value: 'Nothing', text: 'Nothing' },
              { value: 'AccessFailed', text: 'AccessFailed' },
            ]
          },
        },
        {
          label: 'Support',
          field: 'sp',
          width: '90px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
          filterOptions: { enabled: true, filterDropdownItems: [ { value: true, text: 'True' }, { value: false, text: 'False' } ] },
        },
        {
          label: 'Follow',
          field: 'f',
          width: '65px',
          type: 'number',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
          filterOptions: { enabled: true, filterDropdownItems: [ { value: true, text: 'True' }, { value: false, text: 'False' } ] },
        },
        {
          label: 'Note',
          field: 'n',
          width: '65px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          label: 'Created',
          field: 'c',
          width: '150px',
          thClass: 'text-center',
          tdClass: 'text-center',
          sortable: false,
          type: 'date',
          dateInputFormat: 't', // timestamp
          dateOutputFormat: 'y/LL/dd HH:mm', // outputs Mar 16th 2018
        },
        {
          label: 'URL',
          field: 'url',
          tdClass: 'url-font',
          sortable: false,
          filterOptions: { enabled: true, trigger: 'enter' },
        },
      ],
      rows: [],
      show_all:false,
      status:[
        'Attempt',
        'Registered',
        'Fail',
        'Survey',
        'Working',
        'Complete',
        'Notified',
        'UserComplete',
        'Nothing',
        'AccessFailed'
      ],
      note_modal:null,
      id:0,
      note:"",
      note_admin:"",
      info:{}
    }
  },
  computed :{
  },
  created (){
    if ("user" in this.$route.query){
      let user_id = this.$route.query.user;
      this.columns[1].filterOptions.filterValue = user_id;
    }
    if ("id" in this.$route.query){
      let id = this.$route.query.id;
      this.columns[0].filterOptions.filterValue = id;
    }
  },
  async mounted (){
    await this.get();
    this.note_modal = document.getElementById("note-modal");
    this.note_modal.remove();
    this.note_modal.classList.remove("d-none");
  },
  methods: {
    loading (mes=""){
      let spinner = document.createElement("div");
      spinner.classList.add("spinner-border", "text-secondary");
      let p = document.createElement("p");
      p.innerText = mes;
      let div = document.createElement("div");
      div.appendChild(spinner);
      div.appendChild(p);
      this.$swal({ content:div, button:false });
    },
    async get (){
      this.loading();
      let res = null;
      if (this.show_all)
        res = await this.axios.get("/api/v2/attempttargets?all");
      else
        res = await this.axios.get("/api/v2/attempttargets");
      this.rows = res.data;
      this.$swal.close();
    },
    rowStyleClassFn(row) {
      return row.d? 'del-row':'';
    },
    async show_note (id, note, note_admin, info){
      this.id = id;
      this.note = note;
      this.note_admin = note_admin;
      this.info = info;
      let val = await this.$swal({ content:this.note_modal, buttons:["閉じる","更新"], closeOnClickOutside: false });
      if (val){
        try{
          this.loading();
          let data = { id:id, note_admin:this.note_admin };
          await this.axios.post("/api/v2/attempttarget", data);
          this.$swal.close();
        }
        catch (err){
          console.log(err);
          this.$swal("Error","","error")
        }
      }
    },
    async change_status(id,e){
      let data = {
        id:id,
        status:this.status.indexOf(e.target.value)+1
      }
      try{
        this.loading();
        await this.axios.post("/api/v2/attempttarget", data);
        this.$swal.close();
      }
      catch(err){
        console.log(err);
        this.$swal("Error","","error");
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.at2 ::v-deep {
  .vgt-table {
    thead th {
      padding-right: .75em;
    }
    td {
      vertical-align: middle!important;
    }
    .del-row {
      background-color: #e2e3e5;
    }
    a.fill svg {
      fill:#0d6efd;
    }
    tr:hover{
      background-color: #bfe2e9!important;
    }
    .url-font {
      font-size: 14px!important;
    }
    .feather {
      width: 18px;
      height: 18px;
      vertical-align: text-bottom;
      margin-top: 1px;
    }
  }
}
</style>